<template>
  <section class="conOfRequestProcess">
    <v-container fluid v-if="listData && Object.keys(listData).length > 0">
      <v-row>
        <v-col cols="12" class="pb-0">
          <div class="memberBrtCe">
            <router-link
              :to="{
                name: 'allProductsReport',
              }"
            >
              Project
            </router-link>
            <span>/</span>
            <span> Job Orders </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0 d-flex justify-space-between">
          <div class="conOfHeader">
            <div class="headerOfPage">Job Order No#{{ $route.params.id }}</div>
          </div>
          <div>
            <v-btn
              v-if="
                (listData.status == 8 &&
                  user.parent_id == 4 &&
                  user.children_id == null) ||
                (listData.status == 8 && user.parent_id == 10)
              "
              class="reminderNot btnReminderOutline confBtns mx-3"
              @click="sendCEOReminder()"
              :disabled="isLoading"
              :loading="isLoading"
            >
              <v-icon>mdi-bell-outline</v-icon>Ceo
            </v-btn>
            <v-btn
              v-if="
                (listData.status == 1 &&
                  user.parent_id == 4 &&
                  user.children_id == null) ||
                (listData.status == 1 && user.parent_id == 10)
              "
              class="reminderNot btnReminderOutline confBtns"
              @click="sendSalesReminder()"
              :disabled="isLoading"
              :loading="isLoading"
            >
              <v-icon>mdi-bell-outline</v-icon>Sales
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col lg="10" md="9" cols="12">
          <div class="conOfStepper">
            <v-stepper :value="stepProduct" alt-labels elevation="0">
              <v-stepper-header>
                <v-stepper-step step="1" :complete="stepProduct > 1">
                  <div class="txtStep">Job Order Creation</div>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2" :complete="stepProduct > 2">
                  <div class="txtStep">Sales Approval</div>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" :complete="stepProduct > 3">
                  <div class="txtStep">Cost Control Approval</div>
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step
                  v-if="listData.status != 6"
                  step="4"
                  :complete="
                    stepProduct > 4 &&
                    !(
                      (listData.ceo && listData.status == 8) ||
                      listData.status == 9
                    )
                  "
                >
                  <div class="txtStep">Finance Approval</div>
                </v-stepper-step>
                <v-stepper-step
                  v-if="listData.status == 6"
                  step="4"
                  :rules="[() => false]"
                  :complete="stepProduct > 4"
                >
                  <div class="txtStepRed">Finance Reject</div>
                </v-stepper-step>

                <template
                  v-if="
                    (listData.status &&
                      listData.status == 8 &&
                      listData.status != 9) ||
                    listData.ceo
                  "
                >
                  <v-divider></v-divider>

                  <v-stepper-step
                    step="5"
                    :complete="
                      stepProduct > 5 ||
                      (listData.ceo && listData.status == 10) ||
                      (listData.ceo == 1 && listData.status == 6)
                    "
                    v-if="listData.status != 9"
                  >
                    <div class="txtStep">CEO Approval</div>
                  </v-stepper-step>
                  <v-stepper-step
                    step="5"
                    v-if="listData.status == 9"
                    :complete="
                      stepProduct > 5 || (listData.ceo && listData.status == 10)
                    "
                    :rules="[() => false]"
                  >
                    <div class="txtStepRed">CEO Reject</div>
                  </v-stepper-step>
                </template>
                <template
                  v-if="
                    (listData.status == 7 || listData.status == 11) &&
                    listData.country_id == 2
                  "
                >
                  <v-divider></v-divider>
                  <v-stepper-step step="6" :complete="stepProduct > 6">
                    <div class="txtStep">Payment</div>
                  </v-stepper-step>
                </template>
              </v-stepper-header>
            </v-stepper>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col lg="10" md="9" cols="12">
          <div class="conOfCollapseSection">
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex justify-space-between">
                    <div class="headerCollapseSec">Job Order Details</div>
                    <div class="seen" v-if="listData.adel_seen == true">
                      Adel seen <v-icon class="seen">mdi-eye-check</v-icon>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="conOfContentCollapse">
                    <v-row>
                      <v-col cols="12" v-if="listData.url">
                        <div class="conOfEachCon">
                          <div class="leftsECTION">
                            <div><img src="@/assets/img/pdf.png" alt="" /></div>

                            <div class="jobOrd">Job Order</div>
                          </div>
                          <div class="rightSection">
                            <a :href="listData.url" target="_blank">Preview</a>
                          </div>
                        </div>

                        <v-col
                          cols="12"
                          class="px-0"
                          v-if="listData.reason_description"
                        >
                          <v-alert color="red lighten-2" dark>
                            <div class="conOfEachCon">
                              <div class="leftsECTION">
                                <div class="">{{ listData.reason_by }}</div>
                              </div>
                              <div class="rightSection">
                                <div>
                                  {{ listData.reason_description }}
                                </div>
                              </div>
                            </div>
                          </v-alert>
                        </v-col>
                      </v-col>
                    </v-row>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div>
            <div
              class="conBtnActionsStatus text-right mt-5"
              v-if="
                (user.parent_id == 2 || user.id == listData.created_by) &&
                listData.status &&
                listData.status == 1
              "
            >
              <v-btn
                class="approveWithIcon mr-3"
                :ripple="false"
                :disabled="!havePo"
                @click="changeStatusItem(3, false)"
              >
                Approve
              </v-btn>
              <v-btn
                class="addPowithIcon mr-3"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                v-if="!havePo"
                @click="dialogProjectPo = true"
              >
                Add PO
              </v-btn>
              <v-btn
                class="rejectWithIcon"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(2, true)"
              >
                Reject
              </v-btn>
            </div>
          </div>
          <div>
            <div
              class="conBtnActionsStatus text-right mt-5"
              v-if="
                user.parent_id == 9 &&
                listData.status &&
                (listData.status == 3 || listData.status == 4)
              "
            >
              <v-btn
                class="approveWithIcon mr-3"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(5, false)"
              >
                Approve
              </v-btn>
              <v-btn
                class="holdWithIcon"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(4, false)"
              >
                Hold
              </v-btn>
            </div>
          </div>
          <div>
            <div
              class="conBtnActionsStatus text-right mt-5"
              v-if="
                user.parent_id == 10 &&
                listData.status &&
                (listData.status == 5 ||
                  listData.status == 10 ||
                  listData.status == 7)
              "
            >
              <v-btn
                class="approveWithIcon mr-3"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(7, false)"
                v-if="listData.status == 10 && user.country_id == 1"
              >
                Approve
              </v-btn>
              <v-btn
                class="approveWithIcon mr-3"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="dialogAddPercentage = true"
                v-if="listData.status == 10 && user.country_id == 2"
              >
                Approve
              </v-btn>
              <v-btn
                v-if="!listData.ceo && listData.status != 7"
                class="holdWithIcon mr-3"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(8, false)"
              >
                Need CEO Approval
              </v-btn>
              <v-btn
                v-if="
                  (user.parent_id == 9 || user.parent_id == 10) &&
                  listData.status > 2 &&
                  listData.status != 9 &&
                  listData.status != 6
                "
                class="editCostwithIcon mr-3"
                @click="editItem"
              >
                Edit Supplier Cost
              </v-btn>

              <v-btn
                class="rejectWithIcon"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(6, true)"
                v-if="listData.status != 7"
              >
                Reject
              </v-btn>
            </div>
          </div>
          <div>
            <div
              class="conBtnActionsStatus text-right mt-5"
              v-if="
                user.parent_id == 11 && listData.status && listData.status == 8
              "
            >
              <v-btn
                class="approveWithIcon mr-3"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(10, false)"
              >
                Approve
              </v-btn>
              <v-btn
                class="rejectWithIcon"
                :ripple="false"
                :disabled="isLoadingStatusBtn"
                @click="changeStatusItem(9, true)"
              >
                Reject
              </v-btn>
            </div>
          </div>
          <div>
            <v-col
              md="6"
              v-if="
                listData.status &&
                (listData.status == 11 || listData.status == 7)
              "
            >
              <div class="d-flex justify-space-between">
                <div class="text-left">Amount To Pay</div>
                <div>{{ listData.percentage }}</div>
              </div>
            </v-col>
            <div
              class="
                conBtnActionsStatus
                d-flex
                align-center
                justify-space-between
                mt-5
              "
              v-if="
                listData.status &&
                (listData.status == 11 || listData.status == 7)
              "
            >
              <v-col md="6">
                <div class="d-flex justify-space-between">
                  <div class="text-left">Payment Progress</div>
                  <div>{{ listData.paied }}</div>
                </div>
                <v-progress-linear
                  color="#FEBF2C"
                  rounded
                  :value="listData.paied"
                ></v-progress-linear>
              </v-col>

              <v-btn
                v-if="
                  listData.status == 7 && user.parent_id == 18 && listData.paied
                "
                class="editCostwithIcon mr-3"
                @click="dialogAddPayment = true"
              >
                Add Payment
              </v-btn>
            </div>
          </div>
          <v-row class="d-flex">
            <v-col
              cols="6"
              v-if="
                (listData.payments != null &&
                  listData.status &&
                  listData.status == 7) ||
                listData.status == 11
              "
            >
              <div class="commentHeader">
                <div class="">Payments</div>
              </div>

              <v-col md="12" cols="6" class="minHeightPay">
                <div
                  v-for="(payment, index) in listData.payments"
                  :key="index"
                  class="d-flex justify-space-between paymentsBorder"
                >
                  <v-col>
                    <div class="d-flex text-left">
                      Percentage : {{ payment.percentage }}
                    </div>
                  </v-col>
                  <v-col>
                    <div class="text-left">
                      Attachment :
                      <a
                        :href="payment.attachment"
                        target="_blank"
                        class="linkLimited"
                      >
                        {{ payment.attachment }}
                      </a>
                    </div>
                  </v-col>
                </div>
              </v-col>
            </v-col>
            <v-col cols="6">
              <div>
                <v-col md="12" cols="12" class="pa-0">
                  <div class="commentHeader">
                    <div class="">Comment</div>
                  </div>
                </v-col>
                <v-col id="conOfComment" class="minHeight pa-0">
                  <v-col
                    class="commentBorder"
                    v-for="(comment, index) in allComments"
                    :key="index"
                  >
                    <v-row class="contOfComment">
                      <v-col md="12" class="d-flex mt-3 pa-0">
                        <div>
                          <img
                            class="commentAvatar"
                            :src="comment.user.avatar"
                            alt=""
                          />
                        </div>
                        <div class="commentName">{{ comment.user.name }}</div>
                      </v-col>
                      <v-col md="12" class="commentForm ml-5">
                        <div>
                          <div class="commentBody">{{ comment.body }}</div>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="commentTime">{{ comment.created_at }}</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-col>
                <v-col md="12" class="commentFiled">
                  <Mentionable
                    :keys="['@']"
                    :items="listTeam"
                    offset="6"
                    insert-space
                    @open="onOpen"
                    @apply="onApply"
                  >
                    <v-textarea
                      label="comment"
                      rows="3"
                      class="eachInput w-100"
                      v-model="formComment.body"
                      outlined
                      solo
                      hide-details="auto"
                      @keyup.enter="sendComment()"
                      append-icon="mdi-send"
                      @click:append="sendComment()"
                    >
                    </v-textarea>
                    <template #no-result>
                      <div class="dim">No result</div>
                    </template>

                    <template #item-@="{ item }">
                      <div class="user">
                        {{ item.name }}
                      </div>
                    </template>
                  </Mentionable>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-dialog v-model="dialogProjectPo" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD PO</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">PO Number</label>
                <v-text-field
                  class="eachInput"
                  placeholder="PO Number"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItemPO.number"
                  required
                  :disabled="quotationApproved != false"
                  :error-messages="numberErrors"
                  @input="$v.formItemPO.number.$touch()"
                  @blur="$v.formItemPO.number.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel"
                  >Select Quotation Type If you don't have PO</label
                >

                <v-radio-group v-model="quotationApproved" row>
                  <v-radio
                    label="Quotation Approved"
                    color="success"
                    value="Quotation Approved"
                  ></v-radio>
                  <v-radio
                    label="Sample Quotation"
                    color="success"
                    value="Sample Quotation"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogProjectPo">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="savePOItem"
            :disabled="isLoadingSavePO"
            :loading="isLoadingSavePO"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddPercentage" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD Percentage</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Notes</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Notes"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItemPercentage.percentage"
                  required
                  :error-messages="PerErrors"
                  @input="$v.formItemPercentage.percentage.$touch()"
                  @blur="$v.formItemPercentage.percentage.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogProjectPercentage">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="savePercentageItem"
            :disabled="isLoadingSavePercentage"
            :loading="isLoadingSavePercentage"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogAddPayment" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">ADD Payment</span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Payment Number</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Payment Number"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="formItemPayment.percentage"
                  required
                  :error-messages="PaymentErrors"
                  @input="$v.formItemPayment.percentage.$touch()"
                  @blur="$v.formItemPayment.percentage.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel">Attachment File</label>

                <v-file-input
                  class="eachInput"
                  placeholder="Attachment File"
                  outlined
                  solo
                  hide-details="auto"
                  append-icon="mdi-paperclip"
                  v-model="formItemPayment.attachment"
                  required
                  @input="$v.formItemPayment.attachment.$touch()"
                  @blur="$v.formItemPayment.attachment.$touch()"
                  accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/*"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogAddPayment">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="savePaymentItem"
            :disabled="isLoadingSavePayment"
            :loading="isLoadingSavePayment"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteSupplierReason" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 modalTitle"
            >Are you sure you want to delete this cost
          </span>
        </v-card-title>

        <v-card-text class="conFormSubmit">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Reason"
                  rows="3"
                  class="eachInput"
                  outlined
                  solo
                  hide-details="auto"
                  v-model="removeReason.reason"
                  :error-messages="removeReasonErrors"
                  @input="$v.removeReason.reason.$touch()"
                  @blur="$v.removeReason.reason.$touch()"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn
            class="btnCancel"
            tile
            @click="closeDialogDeleteSupplierReason"
          >
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="saveDeleteSupplierReason"
            :disabled="isLoadingRemoveReason"
            :loading="isLoadingRemoveReason"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDialog
      v-if="dialogConform"
      v-model="dialogConform"
      :isRejectBtn="isRejectBtn"
      :isApproveBtn="isApproveBtn"
      @confirmAction="cofirmAction()"
    />
    <v-dialog scrollable v-model="dialogCostEdit" max-width="800" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">Edit Cost</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col>
                <label class="eachLabel">Supplier Name</label>

                <v-text-field
                  class="eachInput"
                  placeholder="Supplier Name"
                  solo
                  outlined
                  v-model="supplier_name"
                ></v-text-field>
              </v-col>
              <v-col>
                <label class="eachLabel">PO Number</label>

                <v-text-field
                  class="eachInput"
                  placeholder="PO Number"
                  solo
                  outlined
                  v-model="po_number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row :key="index" v-for="(request, index) in allrequestsToEdit">
              <div class="deleteBtn">
                <v-btn @click="openDialogDelete(request.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
              <v-col>
                <label class="eachLabel">Request Name</label>

                <v-text-field
                  class="eachInput"
                  placeholder="Request Name"
                  solo
                  outlined
                  v-model="request.item_name"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col>
                <label class="eachLabel">Total Actual Cost</label>
                <v-text-field
                  class="eachInput"
                  placeholder="Actual Cost"
                  solo
                  outlined
                  type="number"
                  v-model="allrequestsToEdit[index].actual_cost"
                  @input="
                    $v.allrequestsToEdit.$each[index].actual_cost.$touch()
                  "
                ></v-text-field>
                <div
                  class="red--text"
                  v-if="$v.allrequestsToEdit.$each[index].actual_cost.$error"
                >
                  Cost is required and should be a number.
                </div>
              </v-col>
              <v-col>
                <label class="eachLabel">Cost per</label>

                <v-select
                  outlined
                  v-model="request.cost_per_id"
                  :items="CostPerList"
                  item-text="name"
                  item-value="id"
                  placeholder="Cost Per"
                  hide-details="true"
                  :menu-props="{ bottom: true, offsetY: true }"
                  required
                  append-icon="mdi-chevron-down"
                ></v-select>
              </v-col>
              <v-col>
                <label class="eachLabel">Quantity</label>
                <v-text-field
                  class="eachInput"
                  placeholder="quantity"
                  solo
                  outlined
                  required
                  v-model="allrequestsToEdit[index].quantity"
                  @input="$v.allrequestsToEdit.$each[index].quantity.$touch()"
                ></v-text-field>
                <div
                  class="red--text"
                  v-if="$v.allrequestsToEdit.$each[index].quantity.$error"
                >
                  Quantity is required and should be a number.
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDialogCostEdit">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            :disabled="isLoadingJoEdit"
            :loading="isLoadingJoEdit"
            @click="saveCostItem"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
  </section>
</template>

<script>
import { Mentionable } from "vue-mention";

import { ApiService } from "@/modules/shared/services/api";

let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric, maxValue, decimal } from "vuelidate/lib/validators";
import ConfirmDialog from "@/modules/shared/components/confirm-popup";
import "floating-vue/dist/style.css";
// const users = [
//   {
//     value: "akryum",
//     firstName: "Guillaume",
//   },
//   {
//     value: "posva",
//     firstName: "Eduardo",
//   },
//   {
//     value: "atinux",
//     firstName: "Sébastien",
//   },
// ];

// const issues = [
//   {
//     value: 123,
//     label: "Error with foo bar",
//     searchText: "foo bar",
//   },
//   {
//     value: 42,
//     label: "Cannot read line",
//     searchText: "foo bar line",
//   },
//   {
//     value: 77,
//     label: "I have a feature suggestion",
//     searchText: "feature",
//   },
// ];

export default {
  mixins: [validationMixin],

  validations() {
    const validations = {
      formItemPO: {
        number: { required },
      },
      formItemPercentage: {
        percentage: { required },
      },
      formItemPayment: {
        percentage: {
          required,
          numeric,
          maxValue: maxValue(
            parseInt(this.restNo != 0 ? this.restNo.replace("%", "") : "0")
          ),
        },
        attachment: { required },
      },
      removeReason: {
        reason: { required },
      },
      allrequestsToEdit: {
        $each: {
          quantity: { required, decimal },
          actual_cost: { required, decimal },
        },
      },
    };
    return validations;
  },
  data: () => ({
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    successMessage: "",
    errorMessage: "",
    text: "",
    items: [],

    CostPerList: [
      {
        id: 1,
        name: "Unit",
      },
      {
        id: 2,
        name: "Total",
      },
    ],
    paymentsList: [],
    havePo: false,
    panel: 0,
    quotationApproved: false,
    sampleQuotation: false,
    stepProduct: 2,
    statusId: null,
    isLoading: false,
    isLoadingStatusBtn: false,
    dialogConform: false,
    dialogCostEdit: false,
    dialogProjectPo: false,
    dialogAddPercentage: false,
    dialogAddPayment: false,
    dialogDeleteSupplierReason: false,
    isLoadingSavePO: false,
    isLoadingJoEdit: false,
    allrequestsToEdit: [],
    supplier_name: "",
    po_number: "",
    restNo: 0,
    listData: null,
    CurrencyList: [],
    menu: false,
    dialogProject: false,
    isRejectBtn: false,
    isApproveBtn: false,
    isLoadingSavePayment: false,
    isLoadingRemoveReason: false,
    formItemPO: {},
    formItemPercentage: {},
    removeReason: {},
    formItemPayment: {},
    formItems: {},
    QuantityError: [],
    errors: false,
    isLoadingSavePercentage: false,
    requestIdToDelete: "",
    formComment: {},
    allComments: "",
    listTeam: [],
    listTeamIds: [],
  }),
  computed: {
    ...mapGetters(["user"]),
    rejectReason() {
      return this.$store.getters.rejectReason;
    },

    paymentPercentage() {
      return this.$store.getters.paymentPercentage;
    },
    paymentcost() {
      return this.$store.getters.paymentcost;
    },
    numberErrors() {
      const errors = [];
      if (!this.$v.formItemPO.number.$dirty) return errors;
      !this.$v.formItemPO.number.required &&
        errors.push("PO Number Is Required.");
      return errors;
    },
    PerErrors() {
      const errors = [];
      if (!this.$v.formItemPercentage.percentage.$dirty) return errors;
      !this.$v.formItemPercentage.percentage.required &&
        errors.push("Notes Is Required.");
      return errors;
    },
    PaymentErrors() {
      const errors = [];
      if (!this.$v.formItemPayment.percentage.$dirty) return errors;
      !this.$v.formItemPayment.percentage.required &&
        errors.push("Percentage Number Is Required.");
      !this.$v.formItemPayment.percentage.numeric &&
        errors.push("Percentage should be a number");
      if (this.restNo != 0) {
        console.log(this.restNo);
        !this.$v.formItemPayment.percentage.maxValue &&
          errors.push(
            "Percentage must not be greater than " +
              this.restNo.replace("%", "")
          );
      }

      return errors;
    },
    removeReasonErrors() {
      const errors = [];
      if (!this.$v.removeReason.reason.$dirty) return errors;
      !this.$v.removeReason.reason.required &&
        errors.push("Reason Is Required.");

      return errors;
    },

    quantityErrors() {
      const errors = [];
      if (!this.$v.allrequestsToEdit.quantity.$dirty) return errors;
      !this.$v.allrequestsToEdit.quantity.required &&
        errors.push("quantity Is Required.");
      !this.$v.allrequestsToEdit.quantity.numeric &&
        errors.push("quantity should be a number");
      return errors;
    },
  },
  methods: {
    sendSalesReminder() {
      this.formItems.job_order_id = this.$route.params.id.toString();
      this.formItems.sales = 1;
      this.isLoading = true;

      console.log("Joborder_id", this.$route.params.id);
      apiServices.post("job-orders/reminder", this.formItems).then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;
          this.isLoading = false;
          console.log("Joborder_id", this.$route.params.id);
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.isLoadingSavePO = false;
        }
      });
    },
    sendCEOReminder() {
      this.formItems.job_order_id = this.$route.params.id.toString();
      this.isLoading = true;

      console.log("Joborder_id", this.$route.params.id);
      apiServices.post("job-orders/reminder", this.formItems).then((res) => {
        if (res) {
          this.successMessage = res.message;
          this.successSnackbar = true;
          this.isLoading = false;
          console.log("Joborder_id", this.$route.params.id);
        } else {
          this.errorMessage = res.data.error || "Somthing Error";
          this.errorSnackbar = true;
          this.isLoadingSavePO = false;
        }
      });
    },
    // onOpen(key) {
    //   this.items = key === "@" ? users : issues;
    // },
    onApply(item) {
      console.log(item, "item");
      this.listTeamIds.push(item.id);
    },
    onOpen() {
      let formComments = {
        project_id: this.listData.project_id,
      };
      apiServices
        .getPayload("comments/users", { params: formComments })
        .then((res) => {
          if (res) {
            this.listTeam = res.data;
          }
        });
    },
    editItem() {
      let formItemjoEdit = {
        job_order_id: this.$route.params.id,
      };

      apiServices
        .getPayload("job-order/edit", { params: formItemjoEdit })
        .then((res) => {
          if (res) {
            this.isLoadingJoEdit = false;

            this.allrequestsToEdit = res.data.requests;
            this.supplier_name = res.data.jo.supplier_name;
            this.po_number = res.data.jo.po_number;
            this.dialogCostEdit = true;
          } else {
            this.isLoadingJoEdit = false;
          }
        });
    },
    openDialogDelete(id) {
      this.dialogDeleteSupplierReason = true;
      this.requestIdToDelete = id;
    },
    saveCostItem() {
      this.$v.$touch();
      if (this.$v.allrequestsToEdit.$error) {
        // this.QuantityError.push(" Quantity Is Required.");
        this.errors = true;
      } else {
        if (Object.keys(this.allrequestsToEdit).length && this.supplier_name) {
          let request_ids = [];
          let actual_costs = [];
          let cost_per_type = [];
          let quantity = [];
          this.allrequestsToEdit.filter((obj) => {
            request_ids.push(obj.id);
            actual_costs.push(obj.actual_cost);
            quantity.push(obj.quantity);
            cost_per_type.push(obj.cost_per_id);
          });
          this.formItems.job_order_id = this.$route.params.id.toString();
          this.formItems.request_ids = request_ids.join();
          this.formItems.actual_costs = actual_costs.join();
          this.formItems.cost_per_type = cost_per_type.join();
          this.formItems.quantity = quantity.join();
          this.formItems.supplier_name = this.supplier_name;
          this.formItems.po_number = this.po_number;
        }
        apiServices.put("job-order/update", this.formItems).then((res) => {
          if (res) {
            this.closeDialogCostEdit();
          } else {
            this.isLoadingJoEdit = false;
          }
        });
      }
    },
    savePOItem() {
      if (this.quotationApproved) {
        this.formItemPO.number = this.quotationApproved;
      } else {
        this.$v.$touch();
        if (this.$v.formItemPO.$error) return;
      }

      this.isLoadingSavePO = true;

      this.formItemPO.project_id = String(this.listData.project_id);
      apiServices.post("pos", this.formItemPO).then((res) => {
        if (res) {
          this.isLoadingSavePO = false;
          this.havePo = true;
          this.closeDialogProjectPo();
        } else {
          this.isLoadingSavePO = false;
        }
      });
    },
    closeDialogProjectPo() {
      this.dialogProjectPo = false;
      this.quotationApproved = false;
      this.editedIndexPO = -1;
      this.$nextTick(() => {
        this.$v.$reset();
        this.formItemPO = Object.assign({}, this.defaultItemPO);
      });
    },
    sendComment() {
      this.formComment.job_order_id = this.$route.params.id;
      this.formComment.user_ids = this.listTeamIds.toString();

      apiServices.post("/comments", this.formComment).then((res) => {
        if (res) {
          this.getAllComment();
          this.formComment.body = "";
          var objDiv = document.getElementById("conOfComment");
          objDiv.scrollTop = objDiv.scrollHeight;
        }
      });
    },
    getAllComment() {
      let formComments = {
        job_order_id: this.$route.params.id,
      };
      apiServices
        .getPayload("/comments", { params: formComments })
        .then((res) => {
          if (res) {
            this.allComments = res.data;
          }
        });
    },
    getusersTeam() {
      let formComments = {
        project_id: this.listData.project_id,
      };
      apiServices
        .getPayload("comments/users", { params: formComments })
        .then((res) => {
          if (res) {
            this.listTeam = res.data;
          }
        });
    },
    saveDeleteSupplierReason() {
      this.isLoadingRemoveReason = true;
      let removeReason = {
        job_order_id: this.$route.params.id.toString(),

        reason: this.removeReason.reason,
        request_ids: this.requestIdToDelete,
      };

      apiServices.post("job-order/remove", removeReason).then((res) => {
        if (res) {
          this.isLoadingRemoveReason = false;
          this.closeDialogDeleteSupplierReason();
          this.closeDialogCostEdit();
          this.getListData(this.$route.params.id);
        } else {
          this.isLoadingRemoveReason = false;
        }
      });
    },
    savePercentageItem() {
      this.$v.$touch();
      if (this.$v.formItemPercentage.$error) return;

      this.isLoadingSavePercentage = true;
      let Percentage = {
        job_order_id: this.$route.params.id,

        percentage: this.formItemPercentage.percentage,
        status: 7,
      };
      function clean(obj) {
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
        return obj;
      }

      let cleanData = clean(Percentage);
      apiServices.post("job-orders/status", cleanData).then((res) => {
        if (res) {
          this.isLoadingSavePercentage = false;
          this.havePercentage = true;
          this.getListData(this.$route.params.id);

          this.closeDialogProjectPercentage();
        } else {
          this.isLoadingSavePercentage = false;
        }
      });
    },
    savePaymentItem() {
      this.$v.$touch();
      if (this.$v.formItemPayment.$error) return;
      let formData = new FormData();
      formData.append("attachment", this.formItemPayment.attachment);
      formData.append("job_order_id", this.$route.params.id);
      formData.append("percentage", this.formItemPayment.percentage);

      this.isLoadingSavePayment = true;

      apiServices.post("payments", formData).then((res) => {
        if (res) {
          this.isLoadingSavePayment = false;
          this.havePayment = true;
          this.closeDialogAddPayment();
          this.getListData(this.$route.params.id);
        } else {
          this.isLoadingSavePayment = false;
        }
      });
    },
    closeDialogProjectPercentage() {
      this.dialogAddPercentage = false;

      this.$nextTick(() => {
        this.$v.$reset();
        this.formItemPercentage = Object.assign({}, this.defaultItemPercentage);
      });
    },

    closeDialogAddPayment() {
      this.dialogAddPayment = false;

      this.$nextTick(() => {
        this.$v.$reset();
        this.formItemPayment = Object.assign({}, this.defaultItemPayment);
      });
    },
    closeDialogDeleteSupplierReason() {
      this.dialogDeleteSupplierReason = false;

      this.$nextTick(() => {
        this.$v.$reset();
        this.removeReason = Object.assign({}, this.defaultReason);
      });
    },
    closeDialogCostEdit() {
      this.dialogCostEdit = false;
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    changeStatusItem(statusId, isRejectBtn, isApproveBtn) {
      this.statusId = statusId;
      this.dialogConform = true;
      this.isRejectBtn = isRejectBtn;
      this.isApproveBtn = isApproveBtn;
    },
    cofirmAction() {
      this.isLoadingStatusBtn = true;
      let costBody = {
        job_order_id: this.$route.params.id,
        status: this.statusId,
        reason: this.rejectReason,
        percentage: this.paymentPercentage,
        payment: this.paymentcost,
      };

      function clean(obj) {
        for (var propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
          }
        }
        return obj;
      }

      let cleanData = clean(costBody);
      apiServices.post("job-orders/status", cleanData).then((res) => {
        if (res) {
          this.isLoadingStatusBtn = false;
          this.dialogConform = false;
          this.getListData(this.$route.params.id);
        } else {
          this.isLoadingStatusBtn = false;
          this.dialogConform = false;
        }
      });
    },
    closeConfirmDialog() {
      this.statusId = null;
      this.dialogConform = false;
    },
    getCurrencyListData() {
      this.CurrencyList = [];
      apiServices.get(`currencies?per_page=100`).then((res) => {
        if (res) {
          this.CurrencyList = res.data;
        }
      });
    },

    getStepWizard(status) {
      if (status == 1) this.stepProduct = 2;
      else if (status == 3 || status == 4) this.stepProduct = 3;
      else if (status == 5 || status == 10) this.stepProduct = 4;
      else if (status == 8) this.stepProduct = 5;
      else if (status == 6) this.stepProduct = 5;
      else if (status == 7) this.stepProduct = 6;
      else if (status == 7 || status == 9 || status == 11) this.stepProduct = 7;
    },
    getListData(id) {
      this.listData = null;
      this.isLoading = true;
      apiServices.get(`job-orders/${id}`).then((res) => {
        if (res) {
          this.listData = res.data;
          this.restNo = res.data.rest;
          if (this.listData.po_number != null) {
            this.havePo = true;
          }
          if (res.data.status) this.getStepWizard(res.data.status);
          this.isLoading = false;
        } else this.isLoading = false;
        // console.log(this.listData);
      });
    },
  },

  created() {
    this.getListData(this.$route.params.id);
    this.getAllComment();
  },
  components: { EmptyState, ConfirmDialog, Mentionable },
  watch: {
    "$route.params.query": {
      handler: function () {
        this.getListData(this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
    dialogConform(val) {
      val || this.closeConfirmDialog();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./_job-order-process.scss";
</style>
